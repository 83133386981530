import { FC, ReactNode } from 'react';

import { generateMailTo } from 'helpers/routes';

interface EmailLinkProps {
  email?: string;
  label?: ReactNode;
  children?: ReactNode;
}

const EmailLink: FC<EmailLinkProps> = ({ email = 'example@example.com', label, children }) => (
  <a href={generateMailTo(email)}>{label ?? children ?? email}</a>
);

export default EmailLink;
