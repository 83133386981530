import './style.scss';

import { FC, useCallback, useRef, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

export const escapeHtml = (value: string) => value.replace(/<(?:.|\n)*?>/gm, '');

const getCaret = (el: Node): number => {
  let caretAt = 0;
  const sel = window.getSelection();

  if (!sel || sel.rangeCount === 0) {
    return caretAt;
  }

  const range = sel.getRangeAt(0);
  const preCaretRange = range.cloneRange();

  preCaretRange.selectNodeContents(el);
  preCaretRange.setEnd(range.endContainer, range.endOffset);

  caretAt = preCaretRange.toString().length;

  return caretAt;
};

const setCaret = (el: Node, offset: number): void => {
  const sel = window.getSelection();
  const range = document.createRange();
  let pos = offset;

  const traverse = (node: Node): boolean => {
    if (node.nodeType === Node.TEXT_NODE) {
      const length = node.textContent?.length ?? 0;
      if (pos <= length) {
        range.setStart(node, pos);
        return true;
      } else {
        pos -= length;
      }
    } else {
      for (const child of node.childNodes) {
        if (traverse(child)) return true;
      }
    }

    return false;
  };

  traverse(el);
  range.collapse(true);
  sel?.removeAllRanges();
  sel?.addRange(range);
};

const transformValue = (data: string, invalidData: string[], isInvalid: boolean) => {
  if (!isInvalid) return data;

  const normalizedData = data.trim();

  const sanitizedInvalidData = invalidData.map((email) => email.trim());

  return sanitizedInvalidData.reduce((acc, email) => {
    const emailRegex = new RegExp(`\\b${email}\\b`, 'g');
    return acc.replace(emailRegex, `<span class="invalid-data">${email}</span>`);
  }, normalizedData);
};

export interface MQFormEditAreaProps {
  dataTestId?: string;
  data: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  isValid?: boolean;
  isInvalid?: boolean;
  invalidData?: string[];
  resize?: 'none' | 'vertical' | 'horizontal';
  ariaLabel?: string;
  disabled?: boolean;
}

const MQFormEditArea: FC<MQFormEditAreaProps> = ({
  dataTestId = 'mq-form-edit-area',
  ariaLabel = 'Editable text area',
  data,
  onChange,
  onBlur,
  isValid,
  isInvalid,
  resize,
  invalidData = [],
  disabled = false,
  ...props
}) => {
  const textAreaElement = useRef<HTMLDivElement>(null);

  const value = useMemo(() => transformValue(data, invalidData, !!isInvalid), [data, invalidData, isInvalid]);

  const [typing, setTyping] = useState(false);

  useEffect(() => {
    if (textAreaElement.current) {
      const caret = {
        position: 0,
      };

      if (typing) {
        caret.position = getCaret(textAreaElement.current);
      }

      if (textAreaElement.current.innerHTML !== value) {
        textAreaElement.current.innerHTML = value;

        if (typing) {
          setCaret(textAreaElement.current, caret.position);
        }
      }

      if (typing) {
        textAreaElement.current.focus();
      }
    }
  }, [value, typing]);

  const handleBlur = useCallback(() => {
    setTyping(false);

    if (textAreaElement.current) {
      textAreaElement.current.blur();
    }

    onBlur?.();
  }, [onBlur]);

  return (
    <div
      {...props}
      ref={textAreaElement}
      data-testid={dataTestId}
      role="textbox"
      aria-multiline="true"
      aria-label={ariaLabel}
      suppressContentEditableWarning
      className={classNames(
        'mq-form-edit-area',
        { 'is-valid': isValid, 'is-invalid': isInvalid, disabled },
        resize && `resize-${resize}`,
      )}
      onInput={(e) => {
        setTyping(true);
        onChange(escapeHtml(e.currentTarget.innerText));
      }}
      onKeyDown={() => {
        setTyping(true);
      }}
      onBlur={(e) => {
        if (e.relatedTarget && e.currentTarget.contains(e.relatedTarget)) {
          e.preventDefault();
        }
        handleBlur();
      }}
      autoCapitalize="off"
      autoCorrect="off"
      contentEditable={!disabled}
    />
  );
};

export default MQFormEditArea;
