import { FC, ReactNode, useState } from 'react';

import { MQImage, MQIcon, MQButton, MQTypo } from '@mentorcliq/ui';

import { STATIC_ASSETS } from 'definitions/assets';
import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import MQGrid from 'modules/MQGrid';
import MQLayout from 'modules/MQLayout';
import MQModal from 'modules/MQModal';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

interface ApiSyncDataModalProps {
  description?: ReactNode;
}

const ApiDataSyncModal: FC<ApiSyncDataModalProps> = ({ description }) => {
  const [showModal, setShowModal] = useState(true);

  const handleRefreshPage = () => {
    setShowModal(false);

    window.location.reload();
  };

  return (
    <MQModal show={showModal} hideOnBackdropClick={false} closableKeys={[]} blurry>
      <MQModal.Body>
        <MQLayout.Section type="content">
          <MQGrid.FlexBox direction="column" align="center" justify="center">
            <MQLayout.Heading center>
              <MQTypo.Title type="h2" center bold>
                <AppFormattedMessage {...APP_GLOBAL_MESSAGES.globalDataSyncErrorTitle} />
              </MQTypo.Title>
            </MQLayout.Heading>
            <MQTypo.Text size="5x" center>
              <AppFormattedMessage {...APP_GLOBAL_MESSAGES.globalDataSyncErrorDescription} />
            </MQTypo.Text>
            {description && (
              <MQTypo.Text size="3x" center>
                {description}
              </MQTypo.Text>
            )}
            <MQImage src={STATIC_ASSETS.images.errors.image429} width={220} />
          </MQGrid.FlexBox>
          <MQButton
            dataTestId="api-data-sync-refresh-page-button"
            onClick={handleRefreshPage}
            startIcon={<MQIcon.Svg icon="rotate" />}
          >
            <AppFormattedMessage {...APP_GLOBAL_MESSAGES.globalRefreshPageButtonLabel} />
          </MQButton>
        </MQLayout.Section>
      </MQModal.Body>
    </MQModal>
  );
};

export default ApiDataSyncModal;
