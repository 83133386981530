import './style.scss';

import { FC, useCallback, useMemo, useState } from 'react';

import {
  FeatureTypeEnum,
  getIntlLocaleSelector,
  getLogoutThunk,
  PermissionsEnum,
  switchAuthMode,
  switchToUserThunk,
} from '@mentorcliq/storage';
import { MQAnimate, MQBadge, MQImage, MQIcon, MQButton, MQTypo } from '@mentorcliq/ui';

import ROUTES from 'routes';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';


import history from 'helpers/history';

import { useAppAuthState } from 'hooks/useAppAuthState';
import { useAppBranding } from 'hooks/useAppBranding';
import { useAppConfigs } from 'hooks/useAppConfigs';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppIntl } from 'hooks/useAppIntl';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppSocket } from 'hooks/useAppSocket';
import { useAppViewAs } from 'hooks/useAppViewAs';

import MQAvatar from 'modules/MQAvatar';
import MQLayout from 'modules/MQLayout';
import MQNav from 'modules/MQNav';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

import AppLink from 'router/AppLink';

import PermissionWrapper from 'components/PermissionWrapper';
import SwitchLangModal from 'components/SwitchLangModal';
import SwitchUserModal from 'components/SwitchUserModal';

import Preview from './Addons/components/Preview';

interface AppHeaderProps {
  preview: boolean;
}

const AppHeader: FC<AppHeaderProps> = ({ preview }) => {
  const intl = useAppIntl();
  const dispatch = useAppDispatch();
  const { unread } = useAppSocket();
  const { logo } = useAppBranding();
  const { viewingUserId } = useAppViewAs();
  const { publicConfigs, privateConfigs } = useAppConfigs();
  const { user, viewer, profile, administrator } = useAppAuthState();
  const intlLocale = useAppSelector(({ intl }) => getIntlLocaleSelector(intl));

  const [toggle, setToggle] = useState(false);
  const [expended, setExpended] = useState(false);
  const [showSwitchUser, setShowSwitchUser] = useState(false);
  const [showSwitchLanguage, setShowSwitchLanguage] = useState(false);

  const handleSignOut = useCallback(() => {
    dispatch(getLogoutThunk()).then(() => {
      history.mq.push(ROUTES.mainPage.data.path);
    });
  }, [dispatch]);

  const handleSwitchToUser = useCallback(
    (id: number) => {
      dispatch(
        switchToUserThunk({
          userId: id,
        }),
      ).then(() => {
        history.mq.push(ROUTES.mainPage.data.path);
      });
    },
    [dispatch],
  );

  const handleToggleAccess = useCallback(() => {
    dispatch(
      switchAuthMode({
        adminMode: !user?.adminMode,
      }),
    ).then(() => {
      history.mq.push(ROUTES.mainPage.data.path);
    });
  }, [dispatch, user?.adminMode]);

  const lang = useMemo(
    () => ({
      label: intlLocale,
      enabled: publicConfigs.languages.list.length > 1,
    }),
    [publicConfigs.languages.list.length, intlLocale],
  );

  const title = useMemo(
    () =>
      publicConfigs.title ??
      intl.formatMessage({
        defaultMessage: 'MentorcliQ',
        id: 'app.header.image.alt',
        description: '[AppHeader] image alt',
      }),
    [publicConfigs.title, intl],
  );

  if (preview) {
    return (
      <Preview
        title={title}
        logo={logo}
        toggle={toggle}
        onToggle={setToggle}
        expended={expended}
        onExpend={setExpended}
      />
    );
  }

  return (
    <div className="app-header">
      {!!viewer && (
        <div className="app-header__section" data-preview="hidden">
          <div className="app-header__line">
            <MQLayout.Heading variant="neutral" compact>
              <MQTypo.Text data-testid="view-mentorcliq-as" bold>
                <AppFormattedMessage
                  defaultMessage="Signed in as {userName}"
                  description="[AppHeader] Signed in as label"
                  id="app.header.signed.mentorcliQ.as"
                  values={{
                    userName: profile?.name,
                  }}
                />
              </MQTypo.Text>
            </MQLayout.Heading>
            <MQButton
              dataTestId="app-header-cancel"
              onClick={handleSignOut}
              startIcon={<MQIcon.Svg icon="xmark-circle__r" />}
            >
              <AppFormattedMessage {...APP_GLOBAL_MESSAGES.cancel} />
            </MQButton>
          </div>
        </div>
      )}
      <div className="app-header__section">
        <MQNav.Navbar className="app-header__navbar" expand="lg" onToggle={setToggle} onExpended={setExpended}>
          <MQNav.Brand className="app-header__brand" isBrand={logo.branded}>
            <AppLink to={ROUTES.mainPage.data.path} className="app-header__logo">
              <MQImage src={logo.src} data-testid="header-logo" alt={title} contain />
            </AppLink>
          </MQNav.Brand>

          {!!user && (
            <MQNav className="app-header__navbar-menu">
              {expended && (
                <MQNav.Toggle data-testid="app-header-toggle" className="app-header__toggle">
                  {toggle ? (
                    <MQIcon.Svg aria-label="Close Menu" className="app-header-toggle__close" size="2x" icon="times" />
                  ) : (
                    <MQIcon.Svg aria-label="Open Menu" className="app-header-toggle__open" size="2x" icon="bars__l" />
                  )}
                </MQNav.Toggle>
              )}
              <MQNav.Collapse data-testid="app-header-collapse" className="app-header__navbar-collapse">
                <MQNav>
                  <MQNav.Link
                    as={AppLink}
                    to={ROUTES.myCliQ.convert({
                      search: {
                        participantId: viewingUserId,
                      },
                    })}
                    className="app-header__navbar-link"
                    dataTestId="app-header-my-cliq"
                  >
                    <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderMyCliQ} />
                  </MQNav.Link>

                  {privateConfigs.diverst.enabled && (
                    <MQNav.Link
                      className="app-header__navbar-link"
                      href={privateConfigs.diverst.link}
                      dataTestId="app-header-my-diverst"
                      target="_blank"
                    >
                      <AppFormattedMessage
                        defaultMessage="My Diverst"
                        description="[AppHeader] My Diverst label"
                        id="app.header.my.diverst"
                      />
                    </MQNav.Link>
                  )}

                  {privateConfigs.learningLab.enabled && (
                    <MQNav.Dropdown
                      variant="secondary"
                      data-testid="app-header-learning-lab-dropdown"
                      triggers={['hover']}
                    >
                      <MQNav.DropdownToggle
                        as={AppLink}
                        data-testid="app-header-learning-lab-link"
                        toggleIcon={<MQIcon.Svg icon="caret-down" />}
                        className="app-header__navbar-link"
                        to={ROUTES.labsList.data.path}
                      >
                        <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderLearningLab} />
                      </MQNav.DropdownToggle>

                      <MQNav.DropdownMenu>
                        {privateConfigs.learningLab.mentorLabEnabled && (
                          <MQNav.DropdownItem as={AppLink} to={ROUTES.mentorLab.data.path}>
                            <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderMentorLab} />
                          </MQNav.DropdownItem>
                        )}
                        {privateConfigs.learningLab.inclusionLabEnabled && (
                          <MQNav.DropdownItem as={AppLink} to={ROUTES.inclusionLab.data.path}>
                            <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderInclusionLab} />
                          </MQNav.DropdownItem>
                        )}
                        {privateConfigs.learningLab.leadershipLabEnabled && (
                          <MQNav.DropdownItem as={AppLink} to={ROUTES.leadershipLab.data.path}>
                            <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderLeadershipLab} />
                          </MQNav.DropdownItem>
                        )}
                      </MQNav.DropdownMenu>
                    </MQNav.Dropdown>
                  )}

                  <PermissionWrapper features={{ value: [FeatureTypeEnum.ResourceLibrary] }}>
                    <MQNav.Link
                      as={AppLink}
                      eventKey="resource-library"
                      to={ROUTES.resourceLibrary.data.path}
                      className="app-header__navbar-link"
                      dataTestId="app-header-resource-library"
                    >
                      <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderResourceLibrary} />
                    </MQNav.Link>
                  </PermissionWrapper>
                  <PermissionWrapper features={{ value: [FeatureTypeEnum.Chat] }}>
                    <MQNav.Link
                      as={AppLink}
                      data-testid="app-header-chat"
                      className="app-header__navbar-link"
                      to={ROUTES.chat.data.path}
                      endIcon={
                        !!unread && (
                          <MQAnimate.Styles dependencies={unread} animation="scale-up-center">
                            <MQBadge.Info variant="warning" size="sm" circle>
                              {unread}
                            </MQBadge.Info>
                          </MQAnimate.Styles>
                        )
                      }
                    >
                      <AppFormattedMessage
                        defaultMessage="Messages"
                        description="[AppHeader] Messages label"
                        id="app.header.messages.label"
                      />
                    </MQNav.Link>
                  </PermissionWrapper>
                </MQNav>
              </MQNav.Collapse>
            </MQNav>
          )}

          <MQNav.Group>
            {!!profile && (
              <MQNav.Dropdown className="app-header__profile" triggers={['click']}>
                <MQNav.DropdownToggle data-testid="app-header-profile-toggle" className="app-header__profile__toggle">
                  <MQAvatar.Image
                    size="sm"
                    title={profile.name ?? ''}
                    imageUrl={profile.picture ?? ''}
                    dataTestId="header-profile-picture"
                  />
                  {!expended && <span className="app-header__profile-real-name">{profile.name}</span>}
                </MQNav.DropdownToggle>
                <MQNav.DropdownMenu data-testid="app-header-profile-menu" className="app-header__profile__menu">
                  <MQNav.DropdownItem
                    data-testid="open-profile"
                    data-preview="disabled"
                    as={AppLink}
                    to={ROUTES.profile.convert({
                      path: {
                        userId: user?.id,
                      },
                    })}
                    className="app-header__profile__menu-item"
                    startIcon={<MQIcon.Svg icon="user-circle__r" className="app-header__profile__menu-item-icon" />}
                  >
                    <span className="app-header__profile__menu-item-title">
                      <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderMyProfile} />
                    </span>
                  </MQNav.DropdownItem>
                  {administrator && (
                    <MQNav.DropdownItem
                      data-testid="toggle-admin-mode"
                      data-preview="disabled"
                      className="app-header__profile__menu-item"
                      startIcon={<MQIcon.Svg icon="random__r" className="app-header__profile__menu-item-icon" />}
                      onClick={handleToggleAccess}
                    >
                      <span className="app-header__profile__menu-item-title">
                        {user?.adminMode ? (
                          <AppFormattedMessage {...APP_GLOBAL_MESSAGES.globalParticipantView} />
                        ) : (
                          <AppFormattedMessage {...APP_GLOBAL_MESSAGES.globalAdminView} />
                        )}
                      </span>
                    </MQNav.DropdownItem>
                  )}

                  <PermissionWrapper permissions={{ value: [PermissionsEnum.SwitchUser] }}>
                    <MQNav.DropdownItem
                      className="app-header__profile__menu-item"
                      data-preview="disabled"
                      data-testid="toggle-switch-user"
                      onClick={() => {
                        setShowSwitchUser(true);
                      }}
                      startIcon={<MQIcon.Svg icon="people-arrows__r" className="app-header__profile__menu-item-icon" />}
                    >
                      <span className="app-header__profile__menu-item-title">
                        <AppFormattedMessage
                          defaultMessage="Switch User Account"
                          description="[AppHeader] Switch User Account label"
                          id="app.header.switch.user.account"
                        />
                      </span>
                    </MQNav.DropdownItem>

                    {!viewer && showSwitchUser && (
                      <SwitchUserModal
                        visible={showSwitchUser}
                        hide={() => {
                          setShowSwitchUser(false);
                        }}
                        switchToUser={handleSwitchToUser}
                      />
                    )}
                  </PermissionWrapper>
                  <PermissionWrapper features={{ value: [FeatureTypeEnum.Support] }}>
                    <MQNav.DropdownItem
                      data-testid="support"
                      data-preview="disabled"
                      as={AppLink}
                      to={ROUTES.support.data.path}
                      className="app-header__profile__menu-item"
                      startIcon={<MQIcon.Svg icon="life-ring__r" className="app-header__profile__menu-item-icon " />}
                    >
                      <span className="app-header__profile__menu-item-title">
                        <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderSupport} />
                      </span>
                    </MQNav.DropdownItem>
                  </PermissionWrapper>
                  <MQNav.DropdownItem
                    data-testid="privacy"
                    data-preview="disabled"
                    as={AppLink}
                    to={ROUTES.privacy.data.path}
                    className="app-header__profile__menu-item"
                    startIcon={<MQIcon.Svg icon="user-shield__r" className="app-header__profile__menu-item-icon" />}
                  >
                    <span className="app-header__profile__menu-item-title">
                      <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderPrivacy} />
                    </span>
                  </MQNav.DropdownItem>
                  <MQNav.DropdownItem
                    className="app-header__profile__menu-item"
                    data-preview="disabled"
                    data-testid="sign-out"
                    onClick={handleSignOut}
                    startIcon={<MQIcon.Svg icon="sign-out__r" className="app-header__profile__menu-item-icon" />}
                  >
                    <span className="app-header__profile__menu-item-title">
                      <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderLogout} />
                    </span>
                  </MQNav.DropdownItem>
                </MQNav.DropdownMenu>
              </MQNav.Dropdown>
            )}

            {!!profile && lang.enabled && <MQNav.Divider direction="vertical" />}

            {lang.enabled && (
              <MQNav.Link
                as={AppLink}
                dataTestId="app-header-language-toggle"
                className="app-header__language"
                onClick={() => {
                  setShowSwitchLanguage(true);
                }}
                startIcon={<MQIcon.Svg icon="globe__r" className="app-header__language-icon" />}
              >
                <span className="app-header__language-text">{lang.label}</span>
              </MQNav.Link>
            )}
          </MQNav.Group>
        </MQNav.Navbar>
      </div>
      {showSwitchLanguage && <SwitchLangModal show={showSwitchLanguage} hide={() => setShowSwitchLanguage(false)} />}
    </div>
  );
};

export default AppHeader;
