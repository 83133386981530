import { ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_ACCESS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  cliqipedia: createRoute({
    params: {
      layoutTypes: [],
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_ACCESS,
    },
    data: {
      path: `/cliqipedia`,
      exact: true,
    },
  }),
};
