import {
  DurationEnum,
  EnrollmentTriggerEventEnum,
  InvitationStyleEnum,
  ParticipantMatchingStyle,
  PredefinedReasonDto,
  ProgramRequestDto,
  ProgramResponseDto,
  ProgramRoleDto,
  RoleEnrollmentInfoDto,
  RoleTypesEnum,
  ScheduledMessageCommunicationConfigDto,
} from '@mentorcliq/storage';

import { getSatisfactionSurveyCustomMessage } from '__mocks/helpers';

export interface SortRoleByType {
  data: any;
  programRole: ProgramRoleDto;
}

export const sortRolesByType = (programRoles: ProgramRoleDto[]): ProgramRoleDto[] =>
  Object.values(RoleTypesEnum).flatMap((type) =>
    programRoles.filter(({ programRoleType }) => programRoleType === type),
  );

export const sortEnrollmentsByType = (enrollments: RoleEnrollmentInfoDto[]): RoleEnrollmentInfoDto[] =>
  Object.keys(RoleTypesEnum).flatMap((programRoleType) =>
    enrollments.filter(({ programRole }) => programRole.programRoleType === programRoleType),
  );

export const sortDataByRole = (data: SortRoleByType[]) => {
  const orderedData = data.toSorted((a, b) => a.programRole.name.localeCompare(b.programRole.name));

  return Object.keys(RoleTypesEnum).flatMap((programRoleType) =>
    orderedData.filter((item) => item.programRole.programRoleType === programRoleType),
  );
};

export const sortRolesByRoleIds = <
  T extends {
    programRoleId: number;
    [key: string]: any;
  },
>(
  programRoles: ProgramRoleDto[],
  items: T[],
): T[] => {
  const roleIds = sortRolesByType(programRoles).map((role) => role.id);
  return [...items].sort((prev, next) => roleIds.indexOf(prev.programRoleId) - roleIds.indexOf(next.programRoleId));
};

export const serializeProgramResponse = (request: ProgramRequestDto): ProgramResponseDto => ({
  ...request,
  id: request.id ?? -1,
  name: request.name ?? '',
  description: request.description ?? '',
  demo: false,
  retired: false,
  programCategory: request.programCategory ?? {
    id: -1,
    name: '',
  },
  programPillar: request.programPillar ?? {
    id: -1,
    name: '',
  },
  invitationStyleConfig: request.invitationStyleConfig ?? {
    id: -1,
    invitationStyle: InvitationStyleEnum.Public,
    openStyleExcludedRoleIds: [],
  },
  relationshipLength: request.relationshipLength ?? 0,
  allowManageRelationship: !!request.allowManageRelationship,
  matchingStyle: request.matchingStyle ?? ParticipantMatchingStyle.OneToOne,
  showProgramDetails: !!request.showProgramDetails,
  programRoles: request.programRoles ?? [],
  matchConfigurations: request.matchConfigurations ?? [],
  enrollmentPreferenceConfig: request.enrollmentPreferenceConfig ?? {
    questions: [],
  },
  preferencesDisplayConfigs: request.preferencesDisplayConfigs ?? [],
  goalsEnabled: !!request.goalsEnabled,
});

export const findManageMatchesOtherReason = (reasons: PredefinedReasonDto[], selectedReasonId: number) =>
  reasons.find((value) => value.other)?.id === selectedReasonId;

export const getUnMatchReason = (
  reasons: PredefinedReasonDto[],
  data: {
    id: number;
    customReason?: string;
  },
) => {
  const predefined = reasons.find((value) => value.id === data.id);

  if (predefined) {
    return predefined.reason;
  }

  const other = reasons.find((value) => value.other);

  if (other?.id === data.id) {
    return data.customReason ?? other.reason;
  }
};

export const findSatisfactionSurveys = (communications: ScheduledMessageCommunicationConfigDto[]) =>
  communications.filter(
    (item) => item.messageConfig.surveyPolicy?.programSurveyId || item.messageConfig.surveyPolicy?.relationshipSurveyId,
  );

export const checkSatisfactionSurveysEnabled = (communications: ScheduledMessageCommunicationConfigDto[]) =>
  !!findSatisfactionSurveys(communications).length;

export const generateSatisfactionSurveyConfig = (params: { userId: number; roleIds: number[] }) => ({
  lastUpdatedUserId: params.userId,
  lastUpdatedTime: Date.now(),
  relativeDateConfig: {
    programRoleIds: params.roleIds,
    relativeDate: {
      interval: 1,
      period: DurationEnum.Month,
    },
    enrollmentStatusRelativeDateCriterion: EnrollmentTriggerEventEnum.Matched,
  },
  messageConfig: {
    surveyPolicy: {
      programSurveyId: -1,
      relationshipSurveyId: -1,
      reminder: {
        period: DurationEnum.Day,
        interval: 4,
      },
    },
    message: getSatisfactionSurveyCustomMessage(),
  },
});
