import './style.scss';

import { FC, useEffect, useMemo, useRef, useState } from 'react';

import {
  addUserMessageThunk,
  askMQAiThunk,
  getAIAnswerLoadingSelector,
  getAiMessagesSelector,
  MQAiMessageSenderEnum,
  MQAiTypeEnum,
} from '@mentorcliq/storage';
import { MQButton, MQIcon, MQTypo, MQScrollable } from '@mentorcliq/ui';
import classNames from 'classnames';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppIntl } from 'hooks/useAppIntl';
import { useAppMount } from 'hooks/useAppMount';
import { useAppSelector } from 'hooks/useAppSelector';

import MQAlert from 'modules/MQAlert';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

import AppChat from 'layout/AppChat';

import ChatMessage from './Addons/components/ChatMessage';
import LoadingMessage from './Addons/components/LoadingMessage';
import { AiAssistantErrors } from './Addons/definitions';

interface ChatWindowProps {
  type: MQAiTypeEnum;
  back: () => void;
}

const ChatWindow: FC<ChatWindowProps> = ({ back, type }) => {
  const intl = useAppIntl();
  const dispatch = useAppDispatch();
  const bottomRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const lastScrollTop = useRef(0);
  const cliqAiMessages = useAppSelector(({ aiChat }) => getAiMessagesSelector(aiChat));
  const cliqAiLoading = useAppSelector(({ aiChat }) => getAIAnswerLoadingSelector(aiChat));
  const [resized, setResized] = useState(false);
  const [isScrollingBottom, setIsScrollingBottom] = useState(false);
  const [isScrollingTop, setIsScrollingTop] = useState(false);
  const [errorStatus, setErrorStatus] = useState<number | null>();

  const lastQuestion = useMemo(
    () => cliqAiMessages[cliqAiMessages.findLastIndex((e) => e.sender === MQAiMessageSenderEnum.User)],
    [cliqAiMessages],
  );

  const handleScrollToBottom = (behavior: ScrollBehavior = 'auto') => {
    bottomRef.current?.scrollIntoView({
      behavior,
    });
  };

  useAppMount(() => {
    handleScrollToBottom();
  });

  useEffect(() => {
    if (isScrollingBottom && !isScrollingTop && cliqAiMessages.at(-1)?.message) {
      handleScrollToBottom();
    }
  }, [isScrollingBottom, isScrollingTop, cliqAiMessages]);

  useEffect(() => {
    if (cliqAiLoading.isPending) {
      handleScrollToBottom('smooth');
    }
  }, [cliqAiLoading.isPending]);

  const handleScroll = () => {
    const element = scrollRef.current;
    if (element) {
      const isBottom = element.scrollHeight - element.scrollTop - element.clientHeight <= 100;
      setIsScrollingBottom(isBottom);
    }
  };

  useEffect(() => {
    if (cliqAiMessages) {
      handleScroll();
    }
  }, [cliqAiMessages]);

  const handleScrollTop = () => {
    const element = scrollRef.current;

    if (element) {
      const currentScrollTop = element.scrollTop;
      if (currentScrollTop < lastScrollTop.current) {
        setIsScrollingTop(true);
      } else {
        setIsScrollingTop(false);
      }

      lastScrollTop.current = currentScrollTop;
    }
  };

  useEffect(() => {
    const element = scrollRef.current;

    if (element) {
      element.addEventListener('scroll', handleScrollTop);
      return () => element.removeEventListener('scroll', handleScrollTop);
    }
  }, [cliqAiMessages]);

  const handleAskAiRequest = async (text: string) => {
    setErrorStatus(null);

    const response = await dispatch(askMQAiThunk({ text, type }));

    if (askMQAiThunk.rejected.match(response)) {
      setErrorStatus(response.payload?.apiError?.response?.data.status);
    }
  };

  const askCliqAi = async (text: string) => {
    await dispatch(
      addUserMessageThunk({ sender: MQAiMessageSenderEnum.User, created: Date.now().toString(), message: text }),
    );

    handleAskAiRequest(text);
  };

  const assistantTypeLabel = useMemo(
    () =>
      type === MQAiTypeEnum.AdminAi
        ? intl.formatMessage(APP_GLOBAL_MESSAGES.adminAiLabel)
        : intl.formatMessage(APP_GLOBAL_MESSAGES.cliqAiLabel),
    [intl, type],
  );

  return (
    <aside className={classNames('chat-window', { resized })} aria-label="AI Assitant Chatbot">
      <div className="chat-window__header">
        <MQButton
          dataTestId="ai-chat-back-button"
          aria-label="Back to AI Assistant Intro"
          variant="icon"
          onClick={back}
        >
          <MQIcon.Svg size="lg" icon="arrow-left" />
        </MQButton>
        <MQTypo.Text bold size="3x" variant="indigo">
          {assistantTypeLabel}
        </MQTypo.Text>
        <MQButton
          dataTestId="ai-chat-resize-button"
          aria-label={resized ? 'Expand Window' : 'Collapse Window'}
          variant="icon"
          onClick={() => setResized(!resized)}
        >
          {resized ? <MQIcon.Svg size="lg" icon="expand" /> : <MQIcon.Svg size="lg" icon="compress" />}
        </MQButton>
      </div>
      <MQScrollable.Wrapper
        instance={scrollRef}
        className="chat-window__body"
        onScroll={handleScroll}
        height="100%"
        role="log"
        aria-live="polite"
        aria-label="Chat Log"
      >
        <ChatMessage
          assistantTypeLabel={assistantTypeLabel}
          sender={MQAiMessageSenderEnum.MQAi}
          message={intl.formatMessage({
            defaultMessage:
              "Hello! I'm your AI assistant, here to help you with MentorcliQ Platform. Whether you need information, assistance with tasks, or just have a question, I'm here to make things easier for you. Just type in what you need, and I'll do my best to provide a quick and helpful response. Let’s get started!",
            description: '[AiAssistant] Chat welcome message',
            id: 'ai.assistant.chat.welcome.message',
          })}
        />

        {cliqAiMessages.map((item, index) => (
          <div key={item.created}>
            <ChatMessage
              assistantTypeLabel={assistantTypeLabel}
              sender={item.sender}
              created={item.created}
              message={item.message}
            />
            {index === cliqAiMessages.length - 1 && <div ref={bottomRef} />}
          </div>
        ))}

        {cliqAiLoading.isPending && cliqAiMessages.at(-1)?.sender !== MQAiMessageSenderEnum.MQAi && (
          <div ref={bottomRef}>
            <LoadingMessage assistantTypeLabel={assistantTypeLabel} />
          </div>
        )}

        {errorStatus === AiAssistantErrors.QueryError && (
          <MQAlert
            variant="danger"
            button={
              <MQButton
                dataTestId="ai-assistant-regenerate-button"
                aria-label="Regenerate Question"
                variant="danger"
                onClick={() => handleAskAiRequest(lastQuestion?.message || '')}
              >
                <AppFormattedMessage
                  defaultMessage="Regenerate Question"
                  description="[AiAssistant.ChatWindow] Regenerate Question button"
                  id="ai.assistant.chat.window.regenerate.question.button"
                />
              </MQButton>
            }
            message={
              <AppFormattedMessage
                defaultMessage="An error occurred while querying AI data."
                description="[AiAssistant.ChatWindow] Internal error message"
                id="ai.assistant.chat.window.monthly.internal.error.message"
              />
            }
          />
        )}

        {errorStatus === AiAssistantErrors.LimitError && (
          <MQAlert
            variant="danger"
            message={
              <AppFormattedMessage
                defaultMessage="Your usage limit for this month has been reached. Please contact support for assistance."
                description="[AiAssistant.ChatWindow] Monthly limit error message"
                id="ai.assistant.chat.window.monthly.limit.error.message"
              />
            }
          />
        )}
      </MQScrollable.Wrapper>

      <div className="chat-window__footer">
        <AppChat.Toolbar
          sendMessage={({ message }) => {
            askCliqAi(message);
          }}
          disabled={cliqAiLoading.isPending}
          placeholder={intl.formatMessage({
            defaultMessage: 'Ask a question...',
            description: '[Chat] Message Input placeholder',
            id: 'chat.ask.question.input.placeholder',
          })}
        />
      </div>
    </aside>
  );
};

export default ChatWindow;
